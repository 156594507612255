import LoadingButton from '@mui/lab/LoadingButton';
import { AlertColor, Divider, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { validate as validateRut } from 'rut.js';

import {
  createAccountWithoutAuthentication,
  getEmailLowerCase,
  requestTemporalPassword,
  validateByIdentification,
  validateClient,
  validateClientV2,
} from '../../../../api/api';
import InputTextController from '../../../../components/Atoms/InputTextController';
import DevelopedBySection from '../../../../components/LayoutAuth/DevelopedBySection';
import HeaderLogo from '../../../../components/LayoutAuth/HeaderLogo';
import HelpSection from '../../../../components/LayoutAuth/HelpSection';
import { VALIDATE_TEMPORAL_PASSWORD } from '../../../../constants/routes';
import BusinessUnitParamsContext from '../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { setValidateAccountParameters } from '../../../../redux/actions/validateAccountActions';
import { useStyles as useStylesGlobal } from '../../../../styles/global/stylesGlobal';
import { useStyles } from './styles';

type FormInputs = {
  identification: string;
};

const NewLoginForm = () => {
  const { businessUnitUUID, tenantUUID, contextUrl } = useContext(BusinessUnitParamsContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const classes = useStyles();
  const history = useHistory();

  const [alertMessage, setAlertMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const { control, handleSubmit, watch } = useForm<FormInputs>({
    mode: 'all',
  });
  const watchAllFields = watch();
  const onSubmit = async (data: FormInputs) => {
    setLoading(true);
    if (!executeRecaptcha) {
      setLoading(false);
      return console.info('Execute recaptcha not yet available');
    }
    const captcha = await executeRecaptcha('submit');
    if (!validateRut(watchAllFields.identification) || /\./.test(watchAllFields.identification)) {
      setAlertMessage('El formato del rut no es válido');
      setLoading(false);
      return;
    }
    try {
      await handleValidateAndCreateUser(data, captcha);
    } catch (error: any) {
      if (error.response.status === 401) {
        try {
          //Api para obtener email segun identificación
          const clientResponse = await validateByIdentification(
            tenantUUID,
            businessUnitUUID,
            'RUT',
            data.identification
          );
          if (clientResponse) {
            //solicitar contrasenia temp
            await requestTemporalPassword(
              captcha,
              businessUnitUUID,
              clientResponse.email,
              contextUrl
            );

            dispatch(
              setValidateAccountParameters({
                email: getEmailLowerCase(clientResponse.email),
                tokenExpirationTime: 60,
                newLogin: true,
              })
            );
            setLoading(false);
            history.push({
              pathname: VALIDATE_TEMPORAL_PASSWORD,
            });
          }
        } catch (temporalPasswordError) {
          setLoading(false);
          onError();
        }
      } else {
        setLoading(false);
        onError();
      }
    }
  };

  const handleValidateAndCreateUser = async (data: FormInputs, captcha: string) => {
    const response = await validateClientV2(
      tenantUUID,
      businessUnitUUID,
      'RUT',
      data.identification,
      contextUrl
    );

    if (!response.email || !response.tokenExpirationTime) {
      handleValidationError();
      return;
    }

    const clientResponse = await createAccountWithoutAuthentication(
      response.createAccountToken,
      businessUnitUUID
    );

    if (!clientResponse) {
      handleCreateUserError();
      return;
    }

    const emailLowerCase = getEmailLowerCase(response.email);
    const resultTempPassword = await requestTemporalPassword(
      captcha,
      businessUnitUUID,
      emailLowerCase,
      contextUrl
    );

    if (!resultTempPassword) {
      handleTempPasswordError();
      return;
    }

    handleSuccess(emailLowerCase);
  };

  const handleSuccess = (emailLowerCase: string) => {
    dispatch(
      setValidateAccountParameters({
        email: emailLowerCase,
        tokenExpirationTime: 60,
        newLogin: true,
      })
    );
    setLoading(false);
    history.push({
      pathname: VALIDATE_TEMPORAL_PASSWORD,
    });
  };

  const handleValidationError = () => {
    setLoading(false);
    onError();

    throw new Error('Error al validar usuario');
  };

  const handleCreateUserError = () => {
    setLoading(false);
    onError();

    throw new Error('Error al crear usuario');
  };

  const handleTempPasswordError = () => {
    setLoading(false);
    onError();

    throw new Error('Error al solicitar contraseña temporal');
  };

  useEffect(() => {
    if (watchAllFields.identification == '' || watchAllFields.identification == undefined) {
      return;
    }
    if (/\./.test(watchAllFields.identification)) {
      setAlertMessage('El formato del rut no es válido');
      return;
    }
    if (validateRut(watchAllFields.identification)) {
      setAlertMessage('');
    } else {
      setAlertMessage('El formato del rut no es válido');
    }
  }, [watchAllFields.identification]);

  const onError = () => {
    if (watchAllFields.identification == '' || watchAllFields.identification == undefined) {
      setAlertMessage('Número de identificación es requerido');
      return;
    }
    setAlertMessage(
      'No pudimos validar los datos ingresados. Verifica la información o comunícate con soporte.'
    );
  };

  return (
    <>
      <HeaderLogo />

      <div className={classes.containerTitle}>
        <Typography className={classes.title}>{'Iniciar sesión'}</Typography>
        <Typography className={classes.description}>
          {'Ingresa tu identificación para acceder.'}
        </Typography>
      </div>

      <div className={classes.containerForm}>
        <form onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off">
          <Grid container rowSpacing={2} columnSpacing={5}>
            <Grid item xs={12}>
              <InputTextController
                name={'identification'}
                label={'Número de identificación'}
                placeholder={'Ingresa número de identificación'}
                control={control}
                minLength={1}
                maxLength={30}
                required={true}
                isIdentificationRutCustom={true}
                helperText={alertMessage ? alertMessage : undefined}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                error={alertMessage ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                loading={loading}
                disabled={loading}
                className={loading ? classes.buttonDisabled : classes.button}
                variant="contained"
                size="medium"
                type="submit"
                sx={{ textTransform: 'inherit' }}
                onSubmit={handleSubmit(onSubmit, onError)}
              >
                {'Continuar'}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </div>

      <div className={classes.containerForm}>
        <Divider className={classes.divider} />

        <div className={classes.containerCreateUser}>
          <HelpSection withPaddingBottom={false} />
        </div>
      </div>

      <DevelopedBySection />
    </>
  );
};

export default NewLoginForm;
